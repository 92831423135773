<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-button
      class="btn btn-gradient-primary mb-1"
      @click="Createpayment(items)"
    >
      + {{ $t('createPaymentSlip') }}
    </b-button>
    <!-- {{ items.re(ele => ele.uid) }} -->
    <b-row>
      <b-col md="3">
        <div class="card p-2 ctx-yellow-card">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <!-- <i class="fas fa-user-edit fa-3x" /> -->
              <img
                src="@/assets/images/anan-img/payment/member.svg"
                alt="member code"
                height="70"
              >
            </div>

            <div class="text-right">
              <h3 class="text-white">
                {{ $t('memberCode') }}
              </h3>

              <h4 class="text-white">
                {{ getoption.username ? getoption.username: '-' }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>

      <b-col md="3">
        <div class="card p-2 ctx-orange-card">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <!-- <i class="fas fa-cart-arrow-down fa-3x" /> -->
              <img
                src="@/assets/images/anan-img/payment/number item.svg"
                alt="member code"
                height="70"
              >
            </div>

            <div class="text-right">
              <h3 class="text-white">
                {{ $t('itemCount') }}
              </h3>

              <h4 class="text-white">
                {{ numberlength }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>

      <b-col md="3">
        <div class="card p-2 ctx-green-card">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <!-- <i class="far fa-money-check-edit-alt fa-3x" /> -->
              <img
                src="@/assets/images/anan-img/payment/must pay.svg"
                alt="member code"
                height="70"
              >
            </div>

            <div class="text-right">
              <h3 class="text-white">
                {{ $t('amountDue') }}
              </h3>

              <h4 class="text-white">
                {{ payment ? Commas(payment): 0 }} {{ $t('baht') }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>

      <b-col md="3">
        <div class="card p-2 ctx-purple-card">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <!-- <i class="far fa-wallet fa-3x" /> -->
              <img
                src="@/assets/images/anan-img/payment/balance.svg"
                alt="member code"
                height="70"
              >
            </div>

            <div class="text-right">
              <h3 class="text-white">
                {{ $t('systemBalance') }}
              </h3>

              <h4 class="text-white">
                {{ getoption.balance ? Commas(getoption.balance): '0.00' }} {{ $t('baht') }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row class="match-height">

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center">

                <div class="d-lg-flex justify-content-between align-items-center px-2">
                  <h3 class="font-weight-bolder">
                    {{ $t('chooseCustomer') }}
                  </h3>
                  <div class="d-flex align-items-center">
                    <v-select
                      v-model="selectCustomer"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="username"
                      :options="option"
                      class="ml-50"
                      style="width:200px;;"
                    />
                    <b-button
                      variant="primary"
                      class="ml-50 w-50"
                      @click="searchCustomer(selectCustomer)"
                    >
                      {{ $t('SearchParcels') }}
                    </b-button>
                  </div>
                </div>

                <div>
                  <!-- <b-input-group>
                  <b-form-input
                    id="basic-password"
                    v-model="search"
                    placeholder="ค้นหา"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group> -->
                </div>
              </div>
            </div>
            <div class="table-container">
              <table id="customers">
                <thead>
                  <tr>
                    <th scope="col">

                      <b-form-checkbox
                        v-model="selectAll"
                        @change="(e) => isIndeterminate(e)"
                      />
                      <!-- :indeterminate="isIndeterminate" -->
                    </th>
                    <th scope="col" />
                    <th scope="col">
                      {{ $t('sequence') }}
                    </th>
                    <th scope="col">
                      {{ $t('parcelCode') }}
                    </th>
                    <th scope="col">
                      {{ $t('orderList') }}
                    </th>
                    <th scope="col">
                      {{ $t('memberCode') }}
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                    <th scope="col">
                      {{ $t('weight') }}
                    </th>
                    <th scope="col">
                      {{ $t('width') }}
                    </th>
                    <th scope="col">
                      {{ $t('height') }}
                    </th>
                    <th scope="col">
                      {{ $t('length') }}
                    </th>
                    <th scope="col">
                      {{ $t('cubic') }}
                    </th>
                    <th scope="col">
                      {{ $t('type') }}
                    </th>
                    <th scope="col">
                      {{ $t('arrivedThailand') }}
                    </th>
                    <th scope="col">
                      {{ $t('checkOrder') }}
                    </th>
                    <th scope="col">
                      {{ $t('repackage') }}
                    </th>
                    <th scope="col">
                      {{ $t('transport') }}
                    </th>
                    <th scope="col">
                      {{ $t('other') }}
                    </th>
                    <th scope="col">
                      {{ $t('cratePricing') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerKilo') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerCubic') }}
                    </th>
                  </tr>
                </thead>
                <thead class="text-center">
                  <tr>
                    <th
                      colspan="6"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproducts()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countweight()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countwidth()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countheight()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproductLong()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ CommasCue(countquantity()) }}</b>
                    </th>
                    <th
                      scope="col"
                      colspan="7"
                      style="background: #fff"
                    />

                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutkilo()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutcue()) }}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,index) in items"
                    :key="index"
                    :class="`ctx-${checkedcolor(item)}`"
                  >
                    <!-- {{ items }} -->
                    <td
                      scope="col"
                      class="text-center"
                    >
                      <b-form-checkbox
                        v-if="item.come_thaiwarehouse"
                        v-model="item.select"
                        @input="checkboxselect(item.select,item)"
                      />
                      {{ selectItem[index] }}
                    </td>
                    <td scope="col">
                      <feather-icon
                        icon="Edit3Icon"
                        class="mr-50 text-primary cursor-pointer"
                        @click="EditType(item)"
                      />
                    </td>
                    <td scope="col">
                      <i
                        v-if="item.transportType === 1"
                        class="fas fa-truck-moving"
                      />
                      <i
                        v-if="item.transportType === 2"
                        class="fas fa-ship"
                      />
                      <router-link :to="{name : 'admin-warehouse-lot'}">
                        {{ item.lot_name ? item.lot_name: '' }}
                      </router-link>{{ item.lot_order ? `/${item.lot_order}`: '' }}
                    </td>
                    <td
                      scope="col"
                      class="text-left"
                    >
                      <router-link :to="{name : 'admin-tracking-edit', params: { id: item._id }}">
                        {{ item.postnumber ? item.postnumber: '-' }}
                      </router-link>
                    </td>
                    <td
                      scope="col"
                      class="text-left"
                    >
                      <router-link v-if="item.order_id" :to="{name : 'admin-order-detail', params: { id: item.order_id }}">
                        {{ item.order_text }}
                      </router-link>
                      <span v-else>-</span>
                      <!-- <router-link :to="{name : 'admin-tracking-edit'}"> -->
                      <!-- {{ item.tag_id ? item.tag_id: '-' }} -->
                      <!-- </router-link> -->
                    </td>
                    <td scope="col">
                      {{ getoption.username }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.weight*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.width) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.height) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.long) }}
                    </td>
                    <td scope="col">
                      {{ CommasCue(item.charge_cue*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ item.product_type ? item.product_type.pro_initial: '-' }}
                    </td>
                    <td scope="col">
                      {{ item.come_thaiwarehouse ? item.come_thaiwarehouse: '-' }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.product_inspection_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.re_wrap_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.shipping_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.other) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.packaging_fee) }}
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_kilo === item.charge_per_save"> {{
                        Commas(item.charge_per_kilo) }}<span class="text-danger">-{{ Commas(item.discount) }}% {{
                        Commas(item.charge_per_save*(100-item.discount)/ 100) }}</span> </span>
                      <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-'
                      }}</span>
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_cue === item.charge_per_save"> {{
                        Commas(item.charge_per_cue)
                      }}<span class="text-danger">-{{ Commas(item.discount) }}% {{
                        Commas(item.charge_per_save*(100-item.discount)/
                          100) }}</span> </span>
                      <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-'
                      }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="text-center">
                  <tr>
                    <th
                      class="text-center"
                      colspan="6"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproducts()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countweight()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countwidth()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countheight()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproductLong()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ CommasCue(countquantity()) }}</b>
                    </th>
                    <th
                      scope="col"
                      colspan="7"
                      style="background: #fff"
                    />

                    <th
                      scope="col"
                      class="text-center"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutkilo()) }}</b>
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutcue()) }}</b>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="d-flex align-items-center justify-content-start p-2">
              <!-- <span class="czx ctx-yellow">
                {{ $t('checkGoods') }}
              </span> -->

              <span class="czx ctx-green">
                {{ $t('checkOrder') }}
              </span>

              <span class="czx ctx-orange">
                {{ $t('woodPacking') }}
              </span>

              <span class="czx ctx-red">
                {{ $t('noPrice') }}
              </span>

              <!-- <span class="czx ctx-purple">
                claim
              </span> -->
            </div>
          </div>
        </b-col>

      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  // BFormInput,
  VBToggle,
  BFormCheckbox,
  // BInputGroupAppend,
  // BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,
    // BInputGroupAppend,
    // BInputGroup,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      type: 'A',
      itemsSelect: [
        { value: 'A' },
        { value: 'B' },
        { value: 'C' },
        { value: 'D' },
        { value: 'D1' },
        { value: 'S' },
      ],
      isActive: true,
      selectAll: [],
      selectItem: { key: 'checkbox', value: false },
      x: [],
      option: [],
      selectCustomer: null,
      getoption: [],
      items: [],
      ProductTypeitem: [],
      numberlength: 0,
      payment: 0,
      arritems: [],
      checkitems: 0,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      showOver: false,
      // items: [
      //   {
      //     id: 1,
      //     name: 'John',
      //     age: 20,
      //     // status: 'orange',
      //   },
      //   {
      //     id: 2,
      //     name: 'Jane',
      //     age: 24,
      //     status: 'green',
      //   },
      //   {
      //     id: 3,
      //     name: 'Susan',
      //     age: 16,
      //     // status: 'red',
      //   },
      //   {
      //     id: 4,
      //     name: 'Chris',
      //     age: 55,
      //     // status: 'purple',
      //   },
      //   {
      //     id: 5,
      //     name: 'Dan',
      //     age: 40,
      //     // status: 'yellow',
      //   },
      // ],
    }
  },
  computed: {
  },
  watch: {
    selectItem: {
      handler() {
        this.updateCheckAllState()
      },
      deep: true,
    },
  },
  mounted() {
    this.getUserall()
    // this.GetData(this.$route.params.id)
    this.sub_role = []
    // this.GetSubrole()
    // this.GetModuleList()
    // this.GetGroupUserList()
  },
  methods: {
    toggleAll() {
      for (let i = 0; i < this.items.length; i + 1) {
        this.selectItem[i] = this.selectAll
      }
    },
    // updateCheckAllState() {
    //   const checkedCount = this.selectItem.filter(item => item).length
    //   this.selectAll = checkedCount === this.selectItem.length
    // },
    getUserall() {
      this.showOver = true
      try {
        this.$http.get('/Paymentuser/getcustomer')
          .then(response => {
            this.showOver = false
            this.option = response.data.data
            this.payment = 0
            this.numberlength = 0
            // console.log(this.option)
          }).catch(err => {
            console.log(err)
            this.showOver = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    async searchCustomer(item) {
      this.showOver = true
      this.getoption = []
      this.getoption = item
      this.payment = 0

      try {
        const obj = {
          uid: item.uid,
        }
        this.$http.post('/Paymentuser/getpostnumber', obj)
          .then(response => {
            this.showOver = false
            this.items = []
            this.arritems = []
            // console.log(response.data)

            // eslint-disable-next-line no-shadow
            const res = response.data.map(item => {
              // eslint-disable-next-line no-param-reassign
              item.select = false
              return item
            })
            this.payment = 0
            this.numberlength = 0
            this.items = res
            this.checkitems = 1
            // console.log(this.items)
            // this.arritem = this.items.forEach(ele => ele.transportType)
            // this.selectItem = this.items.length
            // this.getProduct()
            // console.log(this.items)
            // console.log(this.selectItem)
          })
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    async getProduct() {
      this.$http
        .get('/groupbyuser/show')
        .then(response => {
          if (response.data.success) {
            const Res = response.data.data
            // console.log(ele.transportType)
            this.ProductTypeitem = []
            // eslint-disable-next-line no-restricted-syntax
            for (const items of Res.product_type.filter(item => item.product_type_class)) {
              this.ProductTypeitem.push({
                pro_id: items.product_type_id, pro_name: items.product_type_name, pro_class: items.product_type_class, pro_kilo: items.product_type_kilo, pro_queue: items.product_type_queue,
              })
            }
            // console.log(this.ProductTypeitem)
            this.ProductTypeitem.sort((a, b) => {
              if (a.pro_name < b.pro_name) {
                return -1
              } if (a.pro_name > b.pro_name) {
                return 1
              }
              return 0
            })
            // eslint-disable-next-line no-restricted-syntax
          }
        })
        .catch(error => console.log(error))
    },
    async reitem(item) {
      try {
        const { data: res } = await this.$http.post('/postnumber/update', item)
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    CommasCue(x) {
      if (!x) {
        return '0'
      }
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (parts[1]) {
        parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
      } else {
        parts.push('0000')
      }
      return parts.join('.')
    },
    filteredProductTypeItems(item) {
      if (!item) {
        // console.log(this.ProductTypeitem)
        return []
      }
      const arr = Number(item.pro_class)
      return this.ProductTypeitem.filter(ele => ele.pro_class === arr)
    },
    EditType(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-tracking-edit', params: { id: item._id } })
    },
    Createpayment(item) {
      if (this.checkitems === 0) {
        this.Alert(false, 'กรุณาเลือกลูกค้า')
      }
      if (this.checkitems === 1) {
        this.Alert(false, 'เลือกพัสดุ')
      }
      if (this.arritems.length) {
        this.$router.push({ name: 'admin-payment-create', params: { id: item[0].uid, arritems: this.arritems } })
      }
      // console.log(item)
      // console.log(this.arritems)
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'warning' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      })
    },
    countproducts() {
      return this.items.reduce((total, item) => total + item.product_amount, 0)
    },
    countweight() {
      return this.items.reduce((total, item) => total + (item.weight * item.product_amount), 0)
    },
    countwidth() {
      return this.items.reduce((total, item) => total + (item.width), 0)
    },
    countheight() {
      return this.items.reduce((total, item) => total + (item.height), 0)
    },
    countproductLong() {
      return this.items.reduce((total, item) => total + (item.long), 0)
    },
    countquantity() {
      return this.items.reduce((total, item) => total + (item.charge_cue * item.product_amount), 0)
    },
    conutkilo() {
      return this.items.reduce((total, item) => total + (item.discount && item.charge_per_kilo === item.charge_per_save ? (item.charge_per_save*(100-item.discount)/ 100): item.charge_per_kilo === item.charge_per_save ? (item.charge_per_save): 0 ), 0)
    },
    conutcue() {
      return this.items.reduce((total, item) => total + (item.discount && item.charge_per_cue === item.charge_per_save ? (item.charge_per_save*(100-item.discount)/ 100): item.charge_per_cue === item.charge_per_save ? (item.charge_per_save): 0 ), 0)
    },
    checkboxselect(check, item) {
      if (check) {
        if (item.discount) {
          this.payment += (item.charge_per_save * (100 - item.discount)) / 100
          this.numberlength += 1
          this.arritems.push(item)
          this.checkitems = 2
        } else {
          this.payment += item.charge_per_save
          this.numberlength += 1
          this.arritems.push(item)
          this.checkitems = 2
        }
        // eslint-disable-next-line no-underscore-dangle
      } else {
        const index = this.arritems.indexOf(item)
        if (index !== -1) {
          this.arritems.splice(index, 1)
          if (item.discount) {
            this.payment -= (item.charge_per_save * (100 - item.discount)) / 100
            this.numberlength -= 1
          } else {
            this.payment -= item.charge_per_save
            this.numberlength -= 1
          }
        }
        // this.numberlength -= 1

        // this.payment -= item.charge_per_save
      }
      if (!this.arritems.length) {
        this.checkitems = 1
      }
    },
    isIndeterminate(event) {
      // console.log(event[0])
      this.items.forEach(item => {
        if (event[0]) {
          // eslint-disable-next-line no-param-reassign
          item.select = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.select = false
        }
        return item
      })
      // this.items.map(item => {
      //   if (event[0]) {
      //     // eslint-disable-next-line no-param-reassign
      //     item.select = true
      //   } else {
      //     this.getoption.username = null
      //     this.payment = 0
      //     // eslint-disable-next-line no-param-reassign
      //     item.select = false
      //   }
      //   return item
      // })
      // if (selectAll === true) {
      //   // this.checkboxselect(selectAll, this.items)

      //   this.selectItem.forEach(ele => {
      //     console.log(ele)
      //     // if (ele.out_thaiwarehouse) {
      //     // this.checkboxselect(selectAll, ele)
      //     // this.selectItem =
      //     //   console.log(this.selectItem)
      //     // }
      //   })

      //   // console.log(this.items)
      // } else {
      //   this.numberlength = 0
      //   this.payment = 0
      // }
      // const checkedCount = this.selectItem.filter(item => item).length
      // return checkedCount > 0 && checkedCount < this.selectItem.length
    },
    checkedcolor(item) {
      if (item.packbox && item.checkproduct) {
        return 'yellow'
      } if (item.checkproduct) {
        return 'green'
      } if (item.packbox) {
        return 'orange'
      } if (item.charge_per_save === 0) {
        return 'red'
      }
      return '#ffffff'
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      </style>
